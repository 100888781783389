//import {createMuiTheme} from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';


const theme = createMuiTheme({
    palette : {
        primary : {
            main : "#1976d2", //"#4fc2f7",
            light : "#8bf5ff",           
            dark: "#0092c4",
            contrastText : "#e0f2f1"
        },
        background: {
            default: "#f7f9fc",
        }
    },
    typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
});


export default theme;