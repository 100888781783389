/**
 * Retorna las iniciales de una texto
 *
 * @param {string} str texto.
 * @return {string} Iniciales.
 */
export default function getInitials(str) {
    try {
        if (/\s/g.test(str)) {
            const strs = str.split(" ");
            return (strs.length === 2)
                ? (strs[0].slice(0, 1) + strs[1].slice(0, 2)).toUpperCase()
                : str.split(" ").map((n) => n[0]).join("").toUpperCase();
        } else {
            return str.slice(0, 3).toUpperCase();
        }
    } catch (e) {
        return "";
    }
}