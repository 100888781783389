import React, { useState } from "react";

import { Grid, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from "@material-ui/core";

export const InputDialog = ({ title = "", labels = { inputLabel: "", btnCancel: "", btnOk: "" }, message, isOpen, isLoading, onOk, onCancel }) => {
    const [inputText, setInputText] = useState("");

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    return false;
                }

                if (reason === "escapeKeyDown") {
                    return false;
                }
            }}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        {message}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            label={labels.inputLabel}
                            variant="outlined"
                            fullWidth
                            size="small"
                            multiline
                            minRows={2}
                            maxRows={3}
                            required
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel} color="primary" disabled={isLoading}>
                    {labels.btnCancel}
                </Button>
                <Button onClick={() => onOk(inputText)} color="secondary" disabled={isLoading}>
                    {isLoading && <CircularProgress size={20} style={{ marginRight: 5 }} />} {labels.btnOk}
                </Button>
            </DialogActions>
        </Dialog>
    );
};