export default function getResponseError(error) {
    if (!error || !error.response || !error.response.data) return "Error desconocido";
    const errors = error.response.data?.errors;
    console.log(error.response.data);
    if (!errors) return "Error desconocido";

    if (errors?.Errores) {
        return errors?.Errores?.length > 0 ? errors?.Errores[0] : "Error desconocido";
    } else {
        if(typeof errors === 'object' && errors !== null) {
            return Object.entries(errors).map(e => e[1].toString()).toString();
        } else {
            return errors;
        }
    }
}