import React from 'react';
import { NavLink } from 'react-router-dom';

import { List, ListItem, ListItemText, Divider, ListItemIcon, ListSubheader, Tooltip, makeStyles } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import AllInboxOutlinedIcon from '@material-ui/icons/AllInboxOutlined';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import TimerOffTwoToneIcon from '@material-ui/icons/TimerOffTwoTone';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import AllInboxIconOutlined from '@material-ui/icons/AllInboxOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import { useLocalization } from 'contexto/localization/useLocalization';
import useRoles from 'hooks/useRoles';


const useStyles = makeStyles({
    button: {
        "&.active": {
            background:'#F1F5FD',
            "& .MuiSvgIcon-root": {
                color: '#434CE8'
            },
            "& .MuiTypography-root": {
                color: '#434CE8'
            }
        },
        width: 215,
        borderRadius: 5,
        margin: "0 10px 0 10px",
    },
});

const MenuItem = ({ classes, title, icon, onlyIcon, link, end }) => {
    const styles = useStyles();
    return (
        <ListItem component={NavLink} button to={link} className={styles.button} exact>
            {onlyIcon &&
                <Tooltip title={title} arrow placement="right">
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                </Tooltip>
            }
            {!onlyIcon &&
                <>
                    <ListItemIcon style={{ minWidth: "40px" }}>
                        {icon}
                    </ListItemIcon>
                    <ListItemText classes={{primary: classes }} primary={title} />
                </>
            }
        </ListItem>
    )
};

const LeftMenu = ({ classes, onlyIcon = false, onClickItem }) => {
    const { strings: s } = useLocalization();
    const strings = s.menus;

    const [roles, accesos] = useRoles();

    return (
        <div className={classes.list}>
            <div onClick={onClickItem}>
                <List>
                    <MenuItem classes={classes.listItemText}
                        title={strings.dashboard}
                        icon={<DashboardOutlinedIcon />}
                        onlyIcon={onlyIcon}
                        link="/"
                    />
                </List>
                <Divider />
                {accesos !== undefined && (accesos.some(a => a.name === "DOC" || a.name === "COM" || a.name === "ACT" || a.name === "INF" || a.name === "SDC" || a.name === "MSD") || (accesos.some(a => a.name === "MAC") && (roles.includes("Visador") || roles.includes("Ejecutor")))) &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.cumplimiento}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "DOC") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.documentos}
                                    icon={<DescriptionOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/documentos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "COM") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.compromisos}
                                    icon={<CollectionsBookmarkOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/compromisos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "ACT") &&
                                <MenuItem classes={classes.listItemText}
                                title={strings.actividades}
                                icon={<EventAvailableOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/actividades/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "MAC") && (roles.includes("Visador") || roles.includes("Ejecutor")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misActividades}
                                    icon={<AllInboxOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/misactividades/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "SDC" || a.name === "MSD") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.solicitudesDocumentos}
                                    icon={<DescriptionOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/documentos/solicitudes/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "IDO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.indicadores}
                                    icon={<ShowChartIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/documentos/indicadores/paginador"
                                />
                            }
                            
                            { accesos.some(a => a.name === "INF") && <Divider variant="middle" /> }

                            {accesos.some(a => a.name === "INF") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repProximoVencimiento}
                                    icon={<InsertChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/proximos-vencimientos"
                                />
                            }
                            {accesos.some(a => a.name === "INF") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.actividadesVencidas}
                                    icon={<InsertChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/vencidas"
                                />
                            }
                            {accesos.some(a => a.name === "INF") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repCumplimiento}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/cumplimiento"
                                />
                            }
                            {accesos.some(a => a.name === "INF") &&
                                <MenuItem classes={classes.listItemText}
                                title={strings.repDocumento}
                                icon={<TableChartOutlinedIcon/>}
                                onlyIcon={onlyIcon}
                                link="/reportes/documento"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }

                {accesos !== undefined && (accesos.some(a => a.name === "OFI") || accesos.some(a => a.name === "AOF") || accesos.some(a => a.name === "MAO") || accesos.some(a => a.name === "INO") || accesos.some(a => a.name === "SCO")) &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.oficios}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "OFI") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.oficios}
                                    icon={<InsertDriveFileOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/oficios/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "AOF") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.asignadorOficios}
                                    icon={<EventAvailableOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/actividades-oficios/paginador"
                                />
                            }
                            {(accesos.some(a => a.name === "MAO") && (roles.includes("Visador Oficios") || roles.includes("Ejecutor Oficios"))) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misOficios}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/misactividades-oficios/paginador"
                                />
                            }
                            {(accesos.some(a => a.name ==="OPR")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.solicitudesProrroga}
                                    icon={<AllInboxIconOutlined/>}
                                    onlyIcon={onlyIcon}
                                    link="/solicitud-prorroga/oficios"
                                />
                            }
                            {accesos.some(a => a.name === "SCO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.gestorCodigos}
                                    icon={<LibraryBooksOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/oficios/solicitudesCodigos/paginador"
                                />
                            }

                            {accesos.some(a => a.name === "ROF") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.reasignaciones}
                                    icon={<SwapHorizIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/oficios/solicitudesReasignacion/paginador"
                                />
                            }

                            { accesos.some(a => a.name === "INO") && <Divider variant="middle" /> }

                            {accesos.some(a => a.name === "INO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.atrasos}
                                    icon={<TimerOffTwoToneIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/atrasos-oficios"
                                />
                            }
                            {accesos.some(a => a.name === "INO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repOficios}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/oficios"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }

                {accesos !== undefined && (accesos.some(a => a.name === "PRO") || accesos.some(a => a.name === "RIE") || accesos.some(a => a.name === "MRI") || accesos.some(a => a.name === "MAP") || accesos.some(a => a.name === "IND") || accesos.some(a => a.name === "CTR") || accesos.some(a => a.name === "PLA") || accesos.some(a => a.name === "INR")) &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.riesgos}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "PRO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.procesos}
                                    icon={<AccountTreeOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/procesos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "RIE") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.riesgos}
                                    icon={<AssignmentLateOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/riesgos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "MRI") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misRiesgos}
                                    icon={<AssignmentLateOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/misriesgos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "CTR") &&
                                <MenuItem classes={classes.listItemText}
                                title={strings.controles}
                                icon={<EventAvailableOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/controles/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "PLA") &&
                                <MenuItem classes={classes.listItemText}
                                title={strings.planesAccion}
                                icon={<EventAvailableOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/planes-accion/paginador"
                                />
                            }
                            {(roles.includes("Visador") || roles.includes("Ejecutor")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misControles}
                                    icon={<AllInboxOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/miscontroles/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "EVR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.eventoRiesgos}
                                    icon={<ErrorOutlineOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/eventosRiesgos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "IND") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.indicadores}
                                    icon={<ShowChartIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/indicadores/paginador"
                                />
                            }
                            {roles.includes("Revisor") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misIndicadores}
                                    icon={<AssistantOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/misindicadores/paginador"
                                />
                            }

                            { (accesos.some(a => a.name === "INR") || accesos.some(a => a.name === "MAP")) && <Divider variant="middle" /> }

                            {accesos.some(a => a.name === "MAP") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.mapaCalor}
                                    icon={<GridOnOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/mapaDeCalor"
                                />
                            }

                            {accesos.some(a => a.name === "INR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repRiesgos}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/riesgos"
                                />
                            }

                            {accesos.some(a => a.name === "INR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repIndicadores}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/indicadores"
                                />
                            }
                            {accesos.some(a => a.name === "INR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repControles}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/controles"
                                />
                            }
                            {accesos.some(a => a.name === "INR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repPlanesAccion}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/planesaccion"
                                />
                            }
                            {accesos.some(a => a.name === "INR") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repEventosRiesgos}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/eventosRiesgos"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }

                {accesos !== undefined && accesos.some(a => a.name === "ACI") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.activosInformacion}</ListSubheader>) : null}>
                            <MenuItem classes={classes.listItemText}
                                title={strings.activosInformacion}
                                icon={<BusinessCenterOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/activosInformacion/paginador"
                            />

                            <Divider variant="middle" />
                            
                            {accesos.some(a => a.name === "ACI") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repActivos}
                                    icon={<TableChartOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/activos"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }

                {accesos !== undefined && accesos.some(a => a.name === "AUD") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.auditoria}</ListSubheader>) : null}>
                            <MenuItem classes={classes.listItemText}
                                title={strings.proyectos}
                                icon={<EventAvailableOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/auditoria/proyectos/paginador"
                            />
                            {accesos !== undefined && accesos.some(a => a.name === "MAA") &&
                                <>
                                    <List>
                                        <MenuItem classes={classes.listItemText}
                                            title={strings.misActividades}
                                            icon={<AllInboxOutlinedIcon />}
                                            onlyIcon={onlyIcon}
                                            link="/auditoria/misactividades/paginador"
                                        />
                                    </List>
                                </>
                            }
                        </List>
                        <Divider />
                    </>
                }
                {accesos !== undefined && !accesos.some(a => a.name === "AUD") && accesos.some(a => a.name === "MAA") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.auditoria}</ListSubheader>) : null}>
                            <MenuItem classes={classes.listItemText}
                                title={strings.misActividades}
                                icon={<AllInboxOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/auditoria/misactividades/paginador"
                            />
                        </List>
                        <Divider />
                    </>
                }
                {accesos !== undefined && (accesos.some(a => a.name === "ADT" || a.name === "MAD" || a.name === "MPA" || a.name === "MHR" || a.name === "MCA") || roles.includes("Gerente auditoría") || roles.includes("Gerente") || roles.includes("Gerente general")) &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.auditoria}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "ADT") && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.planesAuditoria}
                                    icon={<GroupWorkOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/planesAuditoria/paginador"
                                />
                            )}
                            {accesos.some(a => a.name === "ADT") && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.auditorias}
                                    icon={<EventAvailableOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/auditorias/paginador"
                                />    
                            )}
                            {(roles.includes("Auditor") || accesos.some(a => a.name === "MAD")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misAuditorias}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/misauditorias/paginador"
                                />
                            }
                            {(roles.includes("Auditor") || accesos.some(a => a.name === "MPA")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misPruebasAuditorias}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/misauditorias/pruebas/paginador"
                                />
                            }
                            {(roles.includes("Auditor") || roles.includes("Auditor líder") || roles.includes("Jefe auditoría") || roles.includes("Gerente auditoría") || (roles.includes("Gerente") && accesos.some(a => a.name === "MCA"))) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misRecomendaciones}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/misrecomendaciones/paginador"
                                />
                            }
                            {(accesos.some(a => a.name === "MCA")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misCompromisos}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/miscompromisos/paginador"
                                />
                            }
                            {accesos.some(a => a.name === "MHR") && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misHorasAuditoria}
                                    icon={<ConfirmationNumberOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/mishorasauditorias/paginador"
                                />    
                            )}
                            {accesos.some(a => a.name === "EAD") && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.reglasAuditoria}
                                    icon={<TimelineIcon/>}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/reglasauditoria/paginador"
                                />
                            )}
                            {accesos.some(a => a.name === "EAD") && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.repReglas}
                                    icon={<TableChartOutlinedIcon/>}
                                    onlyIcon={onlyIcon}
                                    link="/reportes/reglas-auditoria"
                                />
                            )}
                            {roles.includes("Revisor auditoria")&& (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misReglasAuditoria}
                                    icon={<TableChartOutlinedIcon/>}
                                    onlyIcon={onlyIcon}
                                    link="/misreglasauditoria/paginador"
                                />
                            )}
                            {(roles.includes("Gerente auditoría") || roles.includes("Gerente") || roles.includes("Gerente general")) && (
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misprorrogasCompromisos}
                                    icon={<AllInboxIconOutlined/>}
                                    onlyIcon={onlyIcon}
                                    link="/solicitud-prorroga/mis-prorrogas/paginador"
                                />
                            )}
                            {(roles.includes("Auditor") || accesos.some(a => a.name === "MPA")) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misHallazgos}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/auditoria/v2/misauditorias/hallazgos/paginador"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }

                {/* {accesos !== undefined && accesos.some(a => a.name === "TRC") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.tareasCortas}</ListSubheader>) : null}>
                            <MenuItem classes={classes.listItemText}
                                title={strings.tareasCortas}
                                icon={<EventAvailableOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/tareascortas/paginador"
                            />
                            {(accesos !== undefined && accesos.some(a => a.name === "MTC") && (roles.includes("Visador") || roles.includes("Ejecutor"))) &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misTareasCortas}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/mistareascortas/paginador"
                                />
                            }
                        </List>
                        <Divider />
                    </>
                } */}
                {/* {accesos !== undefined && !accesos.some(a => a.name === "TRC") && accesos.some(a => a.name === "MTC") && (roles.includes("Visador") || roles.includes("Ejecutor")) &&
                    <>
                        <List>
                            <MenuItem classes={classes.listItemText}
                                title={strings.misTareasCortas}
                                icon={<AssignmentReturnedOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/mistareascortas/paginador"
                            />
                        </List>
                        <Divider />
                    </>
                } */}
                {accesos !== undefined && accesos.some(a => a.name === "ARE") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.timeReport}</ListSubheader>) : null}>
                            <MenuItem classes={classes.listItemText}
                                title={strings.marcaciones}
                                icon={<ConfirmationNumberOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/timeReport/mis-reportes/marcacion"
                            />
                            <MenuItem classes={classes.listItemText}
                                title={strings.reportesJornadas}
                                icon={<ReceiptOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/timeReport/mis-reportes/paginador"
                            />
                        </List>
                        <Divider />
                    </>
                }

                {accesos !== undefined && accesos.some(a => a.name === "AST" || a.name === "MST") &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.assessment}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "AST") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.assessment}
                                    icon={<BallotOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/assessment/paginador"
                                />
                            }
                            
                            {accesos.some(a => a.name === "MST") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.misEvaluaciones}
                                    icon={<AssignmentReturnedOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link="/assessment/mis-evaluaciones/paginador"
                                />
                            }
                            <Divider variant="middle" />
                        </List>
                        <Divider />
                    </>
                }
                
                {accesos !== undefined && (accesos.some(a => a.name === "INF" || a.name === "INR" || a.name === "INO") || accesos.some(a => a.name === "CAL")) &&
                    <>
                        <List subheader={!onlyIcon ? (<ListSubheader component="div">{strings.extras}</ListSubheader>) : null}>
                            {accesos.some(a => a.name === "CAL") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.calendario}
                                    icon={<CalendarTodayOutlined />}
                                    onlyIcon={onlyIcon}
                                    link="/calendario"
                                />
                            }
                            {accesos.some(a => a.name === "INF" || a.name === "INR" || a.name === "INO") &&
                                <MenuItem classes={classes.listItemText}
                                    title={strings.exportacion}
                                    icon={<ImportExportOutlinedIcon />}
                                    onlyIcon={onlyIcon}
                                    link={accesos.some(a => a.name === "INF")
                                        ? "/reportes/exportacion/1"
                                        : accesos.some(a => a.name === "INR")
                                            ? "/reportes/exportacion/2"
                                            : "/reportes/exportacion/3"
                                    }
                                />
                            }
                        </List>
                        <Divider />
                    </>
                }
                {accesos !== undefined && accesos.some(a => a.name === "CON") &&
                    <>
                        <List>
                            <MenuItem classes={classes.listItemText}
                                title={strings.configuraciones}
                                icon={<TuneOutlinedIcon />}
                                onlyIcon={onlyIcon}
                                link="/configuraciones"
                            />
                        </List>
                    </>
                }
            </div>
        </div>
    )
}
export default LeftMenu;

