import React from "react";

import { Avatar, Button, Grow, MenuList, Paper, Popper, ClickAwayListener } from "@material-ui/core";
import { deepPurple } from '@material-ui/core/colors';

const AvatarMenu = ({ label = "", imageSrc, menuItems }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={{ display: 'flex' }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disableElevation={true}
            >
                <Avatar
                    style={{ backgroundColor: deepPurple[500] }}
                >{label}</Avatar>
            </Button>

            <Popper open={open} onClick={handleClose} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false} style={{ zIndex: 10000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {menuItems}
                            </MenuList>
                        </ClickAwayListener>
                            
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default AvatarMenu;
