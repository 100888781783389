/**
 * Fusiona dos objetos en uno solo
 * @param {any} object1
 * @param {any} object2
 * @returns 
 */
export default function mergeObjects(object1 = {}, object2 = {}) {
    for (const prop in object2) {
        if (object2.hasOwnProperty(prop)) {
            if (typeof object2[prop] === 'object' && object2[prop] !== null) {
                object1[prop] = mergeObjects(object1[prop] || {}, object2[prop]);
            } else {
                object1[prop] = object2[prop];
            }
        }
    }

    return object1;
}