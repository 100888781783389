import React, { useMemo } from "react";

import es_localization from "config/localization/es/core";
//import en_localization from "config/localization/en/core";

export const LocalizationContext = React.createContext({
    localization: es_localization,
    setLocalization: () => {},
});

const LocalizationProvider = ({ children }) => {
    const [localization, setLocalization] = React.useState(es_localization);
    const value = useMemo(() => ({ localization, setLocalization }), [localization]);

    return (
        <LocalizationContext.Provider value={value}>
            {children}
        </LocalizationContext.Provider>
    );
};

export default LocalizationProvider;