import React from "react";

import { Grow, Paper, Popper, ClickAwayListener, Badge, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Typography, makeStyles } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import LensTwoToneIcon from '@material-ui/icons/LensTwoTone';
import { v4 as uuid_v4 } from 'uuid';

import { useLocalization } from "contexto/localization/useLocalization";
import { obtenerNotificacionesUsuarioActual } from "actions/UsuarioAction";
import ModalVerNotificacion from "./ModalVerNotificacion";

const useStyles = makeStyles((theme) => ({
    list: {
        maxHeight: 500,
        overflowY: "scroll",
        overflowX: 'hidden',
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
        '&::-webkit-scrollbar-thumb': { background: '#888' },
        '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
    },
}));
const NotificacionesPopper = () => {
    // Idioma
    const { strings } = useLocalization();
    // Estados
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [notifSelecc, setNotifSelecc] = React.useState({
        id: 0,
        pos: 0,
        open: false,
    });
    const [state, setState] = React.useState({
        notificaciones: [],
        sinLeer: 0,
    })

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const getInfo = React.useCallback(async () => {
        const [notif] = await obtenerNotificacionesUsuarioActual();
        if (notif && notif.data) {
            setState(prev => ({ ...prev, notificaciones: notif.data, sinLeer: notif.data.filter(n => !n.leido).length ?? 0 }));
        }
    }, []);

    React.useEffect(() => {
        getInfo();
    }, [getInfo]);

    const _obtenerIconoTipoNotificacion = React.useCallback((tipo = "") => {
        switch (tipo) {
            case "Login": return <LockOpenIcon />;
            case "ActAsigInicial": return <EventIcon />;
            case "ActAsigPorRep": return <EventIcon />;
            case "ActAgendaInicial": return <EventIcon />;
            case "ActAgendaPorRep": return <EventIcon />;
            case "ActCierreEjecutor": return <EventAvailableIcon />;
            case "ActVisacionVisador": return <AssignmentTurnedInIcon />;
            case "ActAvisoDiario": return <EventNoteIcon />;
            case "ActResumenSemanal": return <DateRangeIcon />;
            case "DocAsigLector": return <NotificationsNoneIcon />;
            default:
        }
    }, []);

    const _obtenerTipoNotificacion = React.useCallback((tipo = "") => {
        switch (tipo) {
            case "Login": return strings.modules.nuevoInicioSesion;
            case "ActAsigInicial": return strings.modules.asignacionActividad;
            case "ActAsigPorRep": return strings.modules.asignacionActividad;
            case "ActAgendaInicial": return strings.modules.asignacionActividad;
            case "ActAgendaPorRep": return strings.modules.asignacionActividad;
            case "ActCierreEjecutor": return strings.modules.cierreDeActividad;
            case "ActVisacionVisador": return strings.modules.visacionDeActividad;
            case "ActAvisoDiario": return strings.modules.avisoDiarioActividad;
            case "ActResumenSemanal": return strings.modules.resumenSemanal;
            case "DocAsigLector": return strings.modules.asignacionLectorDocumento;
            default:
        }
    }, [strings.modules.asignacionActividad, strings.modules.asignacionLectorDocumento, strings.modules.avisoDiarioActividad, strings.modules.cierreDeActividad, strings.modules.nuevoInicioSesion, strings.modules.resumenSemanal, strings.modules.visacionDeActividad]);

    const _AbrirNotificacion = React.useCallback((posNotif = 0, notifId = 0) => {
        setNotifSelecc({ id: notifId, pos: posNotif, open: true });
    }, []);

    const listaNotificaciones = React.useMemo(() => {
        if (state.notificaciones.length > 0) {
            return state.notificaciones.map((n, i) => (
                <ListItem key={uuid_v4()}>
                    <ListItemAvatar>
                        <Badge 
                            overlap="rectangular"
                            variant="dot"
                            color="secondary"
                            invisible={n.leido}
                            children={_obtenerIconoTipoNotificacion(n.tipo)}
                        >
                            <Avatar>
                                {_obtenerIconoTipoNotificacion(n.tipo)}
                            </Avatar>
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant="body1" style={{ fontWeight: n.leido ? 400 : 600 }}>{_obtenerTipoNotificacion(n.tipo)}</Typography>}
                        secondary={new Date(`${n.fechaEnvio}z`).toLocaleString()}
                        onClick={() => _AbrirNotificacion(i, n.id)}
                        style={{ cursor: "pointer" }}
                    />
                </ListItem>
            ));
        } else {
            return (
                <ListItem key={uuid_v4()}>
                    <ListItemAvatar>
                        <Avatar>
                            <LensTwoToneIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={strings.modules.sinNotificaciones}
                    />
                </ListItem>
            )
        }

    }, [_AbrirNotificacion, _obtenerIconoTipoNotificacion, _obtenerTipoNotificacion, state.notificaciones, strings.modules.sinNotificaciones]);

    return (
        <div style={{ display: 'flex' }}>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={state.sinLeer}
                    max={10}
                    color="secondary"
                    children={<NotificationsIcon htmlColor="white" />}
                />
            </IconButton>

            <Popper
                open={open}
                onClick={handleClose}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={false}
                style={{ zIndex: 10000 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                                    {listaNotificaciones}
                                </List>
                            </ClickAwayListener>

                        </Paper>
                    </Grow>
                )}
            </Popper>
            {notifSelecc.open &&
                <ModalVerNotificacion
                    notifId={notifSelecc.id}
                    titulo={_obtenerTipoNotificacion(state.notificaciones[notifSelecc.pos]?.tipo)}
                    open={notifSelecc.open}
                    onRead={getInfo}
                    onClose={() => setNotifSelecc({ id: 0, pos: 0, open: false })}
                />
            }
        </div>
    );
};

export default NotificacionesPopper;
