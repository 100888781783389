import React from 'react';

import { Dialog, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const CustomModal = ({ name = "form1", title, actions, open, padding, maxWidth, fullWidth, overflowY = "auto", children, isForm = true, onClose, onSubmit }) => {

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={maxWidth ?? 'sm'} fullWidth={fullWidth ?? false}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {title}
                </DialogTitle>
                <DialogContent dividers style={{ padding: padding !== undefined ? padding : 16, overflowY: overflowY }}>
                    {isForm &&
                        <form id={name} onSubmit={onSubmit}>
                            {children}
                        </form>
                    }
                    {!isForm && 
                        children
                    }
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
        </div>
    );
}
