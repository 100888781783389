import React from 'react';
import { AppBar, Chip, Divider, Drawer, Hidden, IconButton, makeStyles, useTheme } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import BarSesion from './bar/BarSesion';
import { useStateValue } from '../../contexto/store';
import clsx from 'clsx';
import LeftMenu from './bar/LeftMenu';


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    width: 240,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 240,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  list: {
    width: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': { width: '8px' },
    '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
    '&::-webkit-scrollbar-thumb': { background: '#888' },
    '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
  },
  listItemText: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4B5765"
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const AppNavbar = ({ openMenu = true, onChangeOpenMenu = (state) => { } }) => {
  const [{ sesionUsuario }] = useStateValue();
  const classes = useStyles();
  const theme = useTheme();

  const container = window !== undefined ? () => window.document.body : undefined;

  return sesionUsuario
    ? (sesionUsuario.autenticado === true
      ? (
        <>
          <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: openMenu })} elevation={0}>
            <BarSesion openMenu={openMenu} onChangeOpenMenu={onChangeOpenMenu} />
          </AppBar>
          <Hidden mdUp implementation="js">
            <Drawer
              container={container}
              variant="temporary"
              anchor="left"
              open={openMenu}
              onClose={() => onChangeOpenMenu(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <div className={classes.list}>
                <div className={classes.toolbar}>
                  <LeftMenu classes={classes} onlyIcon={false} onClickItem={() => onChangeOpenMenu(false)}/>
                </div>
              </div>
            </Drawer>
          </Hidden>

          <Hidden smDown implementation="js">
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: openMenu,
                [classes.drawerClose]: !openMenu,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: openMenu,
                  [classes.drawerClose]: !openMenu,
                }),
              }}
            >
              <div className={classes.list}>
                <div className={classes.toolbar}>
                  <Chip label={sesionUsuario && sesionUsuario.usuario ? sesionUsuario.usuario.empresa : "-"} size="small"/>
                  <IconButton onClick={() => onChangeOpenMenu(!openMenu)}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </div>
                <Divider />
                <LeftMenu classes={classes} onlyIcon={!openMenu}/>
              </div>
            </Drawer>
          </Hidden>
        </>
      )
      : null
    )
    : null;
};

export default AppNavbar;